<script setup>
import { ref } from "vue"
import { useAuth } from "@/stores/auth"
import DevOnly from "@/components/DevOnly.vue"
const authStore = useAuth()
const dropdown = ref(false)

const logout = async () => {
  await authStore.logoutAction()
}
</script>

<template>
  <header class="header">
    <!--<div class="flex gap-4 justify-end items-center p-4 lg:hidden">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path fill-rule="evenodd"
          d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10zm0 5.25a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z"
          clip-rule="evenodd" />
      </svg>
    </div>-->
    <div class="flex flex-1 gap-4 justify-end items-center p-4">
      <DevOnly>
        <div class="header-item">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 cursor-not-allowed">
            <path fill-rule="evenodd"
              d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
              clip-rule="evenodd" />
          </svg>
        </div>
      </DevOnly>
      <DevOnly>
        <div class="header-item">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 cursor-not-allowed">
            <path fill-rule="evenodd"
              d="M10 2a6 6 0 00-6 6c0 1.887-.454 3.665-1.257 5.234a.75.75 0 00.515 1.076 32.91 32.91 0 003.256.508 3.5 3.5 0 006.972 0 32.903 32.903 0 003.256-.508.75.75 0 00.515-1.076A11.448 11.448 0 0116 8a6 6 0 00-6-6zM8.05 14.943a33.54 33.54 0 003.9 0 2 2 0 01-3.9 0z"
              clip-rule="evenodd" />
          </svg>
        </div>
      </DevOnly>
      <DevOnly class="divider">
        <div></div>
      </DevOnly>
      <div class="inline-flex gap-2 items-center relative cursor-pointer" @click="dropdown = !dropdown" v-click-outside="() => dropdown ? dropdown = !dropdown : ''">
        <img :src="authStore.user.profile_picture_url" :alt="authStore.user.full_name" class="rounded-full object-fill" width="36" />
        <p v-if="authStore.accountType === 1 || authStore.accountType === 2" class="text-sm font-bold">{{ authStore.organization.name }}</p>
        <p v-else class="text-sm font-bold">{{ authStore.user.first_name }}</p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
          class="w-5 h-5 transition-transform duration-100" :class="{ 'rotate-180': dropdown }">
          <path fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clip-rule="evenodd" />
        </svg>
        <Transition name="drop">
          <div id="dropdown" class="dropdown-menu absolute top-10 right-0 py-2 rounded-md w-48 shadow-lg z-10" v-if="dropdown">
            <div class="dropdown-details flex flex-col pb-2 mb-2">
              <p class="font-bold text-lg px-3">{{ authStore.user.first_name }}</p>
              <p v-if="authStore.accountType === 1 || authStore.accountType === 2" class="text-xs italic px-3">{{ authStore.customer.role.text }}</p>
              <p v-else class="text-xs italic px-3">Étudiant</p>
            </div>
            <nav class="dropdown-nav flex flex-col">
              <ul>
                <li>
                  <a href="https://intercom.help/tu-commences-demain/fr/" target="_blank" class="block font-medium py-1 px-3">
                    Aide
                  </a>
                </li>
                <li>
                  <router-link :to="{name : authStore.accountType === 0 ? 'student.settings' : authStore.accountType === 1 ? 'Company Settings' : 'school.settings' }" class="block font-medium py-1 px-3">Paramètres</router-link>
                </li>
                <li class="cursor-pointer" @click="logout()">
                  <p class="font-medium py-1 px-3 logout">Déconnexion</p>
                </li>
              </ul>
            </nav>
          </div>
        </Transition>
      </div>
    </div>
  </header>
</template>

<style scoped>
.header {
  background-color: var(--color-background-soft);
  display: flex;
  justify-content: space-between;
}

.header-item {
  background-color: var(--color-background);
  padding: .5rem;
  border-radius: 50%;
}

.divider {
  height: 100%;
  width: 1px;
  background-color: var(--color-border);
}

.dropdown-menu {
  background-color: var(--color-background);
  border: 1px solid var(--color-background-mute);
  cursor: auto;
}

.dropdown-details {
  border-bottom: 1px solid var(--color-background-mute);
}

.dropdown-nav li:hover {
  background-color: var(--color-background-soft);
}

.drop-enter-active {
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
}

.drop-leave-active {
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
}

.drop-enter-from, .drop-leave-to {
  transform: translateY(-1.5rem);
  opacity: 0;
}

.logout {
  color: var(--error-400);
}
</style>